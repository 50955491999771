import axios, {AxiosResponse} from 'axios';
import {timer} from 'rxjs';
import {addErrorHandler, AppError, getAppStatus, LOAD_ERROR, registerApplication, start, getAppNames} from 'single-spa';
import {AppDataByCliRole, ApplVer, ApplWidget, HasuraResponse, ParcelMap} from './classes/root-config.classes';
import GlobalEventBus from './customProps/GlobalEventBus';
import GlobalEventStore from './customProps/GlobalEventStore';
import ParcelHelpers from './customProps/ParcelHelpers';
import {applSclRefId} from './enums/root-config.enums';

const jwtDecode = require("jwt-decode");
const globalEventBus = new GlobalEventBus();
const globalEventStore = new GlobalEventStore();

// for running single spa locally, below variable should point to dev proxy
const userManagementUrl = isLocal() ? "https://dev-oic-clinical.optum.com/singleSpaConfig" : "/singleSpaConfig";
const hostname = "optumcare.optum.com";
const pageTitle = "OCM";
const nestedWidgetSectionPrefix = "router_%"
const excludedAppRegistrations = ["scheduling", "ovcp_visits", "virtual_visit", "ovc_accounts", "assessment_proxy"];
const consoleBetaStorageKey = '#console-beta-enabled';

let landingAppConfig: ApplVer;
let appNames$: any;
const parcelMap: ParcelMap = new ParcelMap();

/**
 * shell app v2
 */
export const userManagementCdsUiConfig: ApplVer = {
  appl_nm: "system_mgmt",
  appl_ver_id: "1.0.0",
  appl_endpt_url: isLocal() ? "https://localhost:4202/main.js" : "/microproduct/optumcare-user-management-cds-ui-service.oic-curo.svc.cluster.local/main.js",
  bas_mnu_url: "/",
  appl: {
    appl_nm: "system_mgmt",
    appl_widgets: [],
    appl_scl_ref_id: applSclRefId.Product
  },
  appl_ver_chlds: []
};


function init() {
  landingAppConfig = getLandingPageConfig();
  const element = document.getElementById("page-title");
  if (element) {
    element.innerText = pageTitle;
  }

  console.log("single-spa divs loaded:");
  console.log(document.getElementsByClassName("spa-app"));

  registerSpaAndWatchForToken(landingAppConfig);
}

/**
 * determines which app to load as the 'shell' app
 * when ##console-beta-enabled is included in initial route on load, load Console Beta
 */
export function getLandingPageConfig() {
  if (consoleBetaEnabled()) {
    setConsoleBetaStorage(true);
    userManagementCdsUiConfig.appl_endpt_url = "/microproduct/user-management-cds-ui-2-0-service.core.svc.cluster.local/main.js"
  }
  return userManagementCdsUiConfig;
}

/**
 * set session storage item
 * @param val
 */
export function setConsoleBetaStorage(val: boolean): void {
  sessionStorage.setItem(consoleBetaStorageKey, String(val));
}

export function consoleBetaEnabled(): boolean {
  return isLocalOrDev() && (location.hash.includes(consoleBetaStorageKey) || sessionStorage.getItem(consoleBetaStorageKey) === 'true');
}

function registerSpaAndWatchForToken(config: ApplVer) {
  addSingleSpaErrorHandler();
  registerSingleSpaApplication(config);
  watchEcpTokenInStorage();
}

/**
 * Register apps
 * - Running local will point to dev env or local main.js files if they are used.
 */
export function registerSingleSpaApplication(app: ApplVer) {
  if (app.appl_endpt_url && app.bas_mnu_url) {
    registerWidgets(app);
    registerChildAppsAndParcels(app);
    const parcelHelpers = new ParcelHelpers(app.appl_nm, parcelMap.get(app.appl_nm)); // get map of parcel for parent app
    const appUrl = getAppUrl(app.appl_endpt_url);
    console.log("Registering app:", app.appl_nm, `v${app.appl_ver_id}`, 'at', app.bas_mnu_url);
    registerApplication({
      name: app.appl_nm,
      app: () => System.import(appUrl),
      activeWhen: app.bas_mnu_url,
      customProps: { globalEventBus, globalEventStore, parcelHelpers },
    });
  }
}

/**
 * loop through the appl_widgets and look for nested applications to mount
 * @deprecated, will be replaced with nestedApps defined in table `appl_ver_chld`
 * @param app
 * @deprecated - will be replaced w/ child apps
 */
export function registerWidgets(app: ApplVer) {
  app?.appl?.appl_widgets?.forEach(widget => {
    if(widget?.widget_desc?.appl_endpt_url && widget?.widget_desc?.route) {
      registerWidget(widget, app);
    }
  });
}

/**
 * loop through nested apps v2
 * @param app
 */
export function registerChildAppsAndParcels(app: ApplVer) {
  app?.appl_ver_chlds?.forEach(chldAppl => {
    if (chldAppl.chld_appl?.appl?.appl_scl_ref_id === applSclRefId.Parcel) {
      registerParcel(app.appl_nm, chldAppl.chld_appl);
    } else if(chldAppl.chld_appl?.appl?.appl_scl_ref_id === applSclRefId.NestedProduct) {
      registerChildApp(app, chldAppl.chld_appl);
    }
    registerChildAppsAndParcels(chldAppl?.chld_appl);
  });
}

export function registerParcel(parentApplName: string, applVer: ApplVer) {
  console.log(`Registering parcel:`, applVer.appl_nm, 'within app', parentApplName)
  const parcel = {...applVer, appl_endpt_url: getAppUrl(applVer.appl_endpt_url)}
  parcelMap.push(parentApplName, parcel.appl_nm, parcel);
}

/**
 * v2 evolution of nested widgets
 * @param parentAppl
 * @param childAppl
 */
export function registerChildApp(parentAppl: ApplVer, childAppl: ApplVer) {
  const parentApplNm = parentAppl?.appl_nm;
  const childApplNm = childAppl?.appl_nm;
  const registeredApplNm = `widget_${parentApplNm}_${childApplNm}`;
  const isChildRegisteredAlready: boolean = !!getAppNames().find(a => a === registeredApplNm);
  if (!isChildRegisteredAlready) {
    const nestedRoute = getNestedRoute(childAppl?.bas_mnu_url, parentAppl?.bas_mnu_url);
    const childApplUrl = getAppUrl(childAppl?.appl_endpt_url);
    const parcelHelpers = new ParcelHelpers(parentApplNm, parcelMap.get(parentApplNm)); // get map of parcel for parent app
    if (nestedRoute && childApplUrl) {
      console.log('Registering child app:', registeredApplNm, 'at', nestedRoute);
      registerApplication({
        name: registeredApplNm,
        app: () => System.import(childApplUrl),
        activeWhen: (location: Location) =>  {
          return location.pathname.match(nestedRoute) !== null;
        },
        customProps: { globalEventBus, globalEventStore, parcelHelpers },
      });
    }
  }
}

/**
 * for local environment point to dev otherwise use URl as is
 * @param applUrl
 */
export function getAppUrl(applUrl: string): string {
  if (isLocal()) {
    return applUrl.startsWith('/microproduct/') ? `https://dev-${hostname}${applUrl}` : applUrl;
  }
  return applUrl;
}

/**
 * register a nested widget, matching on named angular router
 * widgets are named and linked to parent mp path
 * @param widget
 * @param parentApp
 * @deprecated - will be replaced w/ child apps
 */
export function registerWidget(widget: ApplWidget, parentApp: ApplVer) {
  const nestedRoute = getNestedRoute(widget?.widget_desc['route'], parentApp.bas_mnu_url);
  const applUrl = getAppUrl(widget?.widget_desc?.appl_endpt_url);
  const appName = `widget_${parentApp?.appl_nm}_${widget?.widget_nm}`;

  if (nestedRoute && applUrl) {
    console.log('Registering nested widget:', appName, 'at', nestedRoute)
    registerApplication({
      name: appName,
      app: () => System.import(applUrl),
      activeWhen: (location: Location) =>  {
        return location.pathname.match(nestedRoute) !== null;
      },
      customProps: { globalEventBus, globalEventStore },
    });
  }
}

start({ urlRerouteOnly: true });

init();

export function watchEcpTokenInStorage(): void {
  const ecp_token = localStorage.getItem("ecp_token");
  if (!ecp_token || window.location.pathname === '/sso') {
    appNames$ = timer(200);
    appNames$.subscribe(() => {
      watchEcpTokenInStorage();
    });
  } else {
    loadUsersApps(ecp_token);
  }
}

function loadUsersApps(ecp_token: string) {
  const decoded_ecp_token = jwtDecode(ecp_token);
  const cli_orgs_array = decoded_ecp_token["x-ecp-claims"]["x-ecp-cli-orgs"];
  const cli_orgs: string[] = [];
  const func_roles: string[] = [];
  cli_orgs_array.forEach((cli_org_object) => {
    cli_orgs.push(cli_org_object["org-id"]);
    const func_roles_array = cli_org_object["func-roles"];
    func_roles_array.forEach((func_role_object) => {
      func_roles.push(func_role_object["role-name"]);
    });
  });
  if (cli_orgs && func_roles) {
    axios.post(userManagementUrl, getUsersApps(cli_orgs, func_roles), getUserAppsHeaders())
        .then((response: AxiosResponse<HasuraResponse<AppDataByCliRole>>) => {
          const apps = response?.data?.data?.cli_func_role_appl || [];
          // filter and map nested micro products for loading
          const app_names = apps
              .filter((app) => landingAppConfig.appl_nm !== app.appl_ver.appl_nm && app.appl_ver.appl_endpt_url && app.appl_ver.bas_mnu_url)
              .map(app => ({ name: app.appl_ver.appl_nm, url: app.appl_ver.bas_mnu_url }))
          localStorage.setItem("app_names", JSON.stringify(app_names));
          appNames$ = null;
          if (apps && apps.length > 0) {
            // wait for shell app to load nested apps in DOM before registering with single-spa
            waitForElement(`[id^='single-spa-application'] [id^='single-spa-application']`).then(() => {
              apps.forEach((appConfig) => {
                // don't register the same app that was already registered initially OR ones that should be excluded
                if (landingAppConfig.appl_nm !== appConfig.appl_ver.appl_nm && !excludedAppRegistrations.includes(appConfig.appl_ver.appl_nm)) {
                  registerSingleSpaApplication(appConfig.appl_ver);
                }
              });
            })
          }
          handleGraphqlErrors(response);
        })
        .catch(function (error) {
          console.error(`Request to ${userManagementUrl} failed. \n\n ${error.response}. \n\n Micro products will not load correctly.`);
        });
  }
}

/**
 * handle graphql errors
 * @param response
 */
export function handleGraphqlErrors(response: AxiosResponse<HasuraResponse<AppDataByCliRole>>) {
  if (response?.data?.errors) {
    const code = response?.data?.errors?.[0]?.extensions?.code;
    const message = response?.data?.errors?.[0]?.message;
    const errorPrefix = `Request to ${userManagementUrl} failed. \n\n Code: '${code}'. \n Message: '${message}'. \n\n Micro products will not load correctly.`
    switch(code) {
      case "invalid-headers":
        // environment variable `HASURA_GRAPHQL_UNAUTHORIZED_ROLE=single_spa_config` missing on `user-management-hasura-engine` could be the issue
        console.error(`${errorPrefix} \n Hasura validation failed, this is likely due to a configuration issue.`);
        break;
      default:
        console.error(`${errorPrefix}`);
        break;
    }
  }
}

export function addSingleSpaErrorHandler() {
  addErrorHandler((error: AppError) => {
    if (getAppStatus(error.appOrParcelName) === LOAD_ERROR) {
      const errorPrefix = `Micro product ${error.appOrParcelName} failed to load.\n\n Status: '${getAppStatus(error.appOrParcelName)}'. \n Message: '${error.message}'.`;
      console.error(`${errorPrefix} \n\n Confirm the file above is available.`);
    }
  });
}

/**
 * waits for element(s) of provided selector
 * used to prevent race conditions with nested micro products
 * @param selector
 */
export async function waitForElement(selector: string): Promise<any> {
  const start = new Date();
  while ( document.querySelector(selector) === null) {
    await new Promise( resolve => requestAnimationFrame(resolve) )
  }
  console.log(`${selector} took: ${(new Date()).valueOf() - (start).valueOf()} ms to load`) ;
  return document.querySelector(selector);
}

/**
 * get all the apps user in context has client/role access for
 * appl_widgets used for nested apps, requires ui_sect_nm starts with `router_`
 * @param cli_orgs
 * @param func_roles
 */
export function getUsersApps(cli_orgs: string[], func_roles: string[]) {
  const formattedDate = new Date()
    .toISOString()
    .replace(/T.*/, "")
    .split("-")
    .join("-");
  return {
    // do we want order_by: {appl_ver_id: desc}?
    query: `
            query get_users_apps {
                cli_func_role_appl(distinct_on: appl_nm, where: {cli_org_id: {_in: ${JSON.stringify(
                  cli_orgs
                )}}, func_role_nm: {_in: ${JSON.stringify(
      func_roles
    )}}, _or: [{end_dt: {_is_null: true}}, {end_dt: {_gt: "${formattedDate}"}}]}) {
                    appl_ver {
                        appl_nm
                        appl_ver_id
                        bas_mnu_url
                        appl_endpt_url
                        appl {
                          appl_widgets(where: {ui_sect_nm: {_like: "${nestedWidgetSectionPrefix}"}}) {
                            widget_nm
                            widget_desc
                            ui_sect_nm
                          }
                        }
                    }
                }
            }
        `,
    variables: {},
  };
}

//  TODO: use this one once DB changes are made.
// export function getUsersApps(cli_orgs: string[], func_roles: string[]) {
//   const formattedDate = new Date()
//     .toISOString()
//     .replace(/T.*/, "")
//     .split("-")
//     .join("-");
//   return {
//     // do we want order_by: {appl_ver_id: desc}?
//     query: `
//             query get_users_apps {
//                 cli_func_role_appl(distinct_on: appl_nm, where: {cli_org_id: {_in: ${JSON.stringify(
//                   cli_orgs
//                 )}}, func_role_nm: {_in: ${JSON.stringify(
//       func_roles
//     )}}, _or: [{end_dt: {_is_null: true}}, {end_dt: {_gt: "${formattedDate}"}}]}) {
//                     appl_ver {
//                         appl_nm
//                         appl_ver_id
//                         bas_mnu_url
//                         appl_endpt_url
//                         appl {
//                           appl_widgets(where: {ui_sect_nm: {_like: "${nestedWidgetSectionPrefix}"}}) {
//                             widget_nm
//                             widget_desc
//                             ui_sect_nm
//                           }
//                           appl_scl_ref_id             # not parcel or child app
//                         }
//                         appl_ver_chlds {              # level 1 association
//                           chld_appl {                 # child app or parcel
//                             appl_nm
//                             appl_ver_id
//                             appl_endpt_url
//                             bas_mnu_url
//                             appl {
//                               appl_scl_ref_id # needs to be child app or parcel
//                             }
//                             appl_ver_chlds {          # level 2 association
//                               chld_appl {             # parcel
//                                 appl_nm
//                                 appl_ver_id
//                                 appl_endpt_url
//                                 appl {
//                                   appl_scl_ref_id # needs to be parcel
//                                 }
//                                 appl_ver_chlds {      # level 3 association
//                                   chld_appl {         # parcel (only if within child app)
//                                     appl_nm
//                                     appl_ver_id
//                                     appl_endpt_url
//                                     appl {
//                                       appl_scl_ref_id # needs to be parcel
//                                     }
//                                   }
//                                 }
//                               }
//                             }
//                           }
//                         }
//                     }
//                 }
//             }
//         `,
//     variables: {},
//   };
// }

/**
 * headers for retrieving apps user is authorized to access
 */
export function getUserAppsHeaders() {
  const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": `Bearer ${localStorage.getItem("ecp_token")}`,
    "x-hasura-role": "single_spa_config"
  };
  return { headers: headers };
}

/**
 * determine if we are running localhost, maybe this should be env var instead?
 */
export function isLocal(): boolean {
  return window.location.host === 'localhost:4200';
}

export function isLocalOrDev(): boolean {
  return window.location.host === 'localhost:4200' || window.location.host === 'dev-oic-clinical.optum.com';
}

/**
 * nested routes use a named angular router outlet
 * they follow the pattern of [parent base url]([outlet name]:[route-path]/[optional-child-path(s)]) in the url
 * @example: /parent-route(ovc_scheduling:route-1/route-2)
 * @param auxRoute
 * @param parentBaseUrl
 * @return nestedRoute, string used to regex if named outlet matches, works with multiple outlets
 */
export function getNestedRoute(auxRoute: string, parentBaseUrl: string): string {
  let nestedRoute: string = null;
  const namedRouteRegex: RegExp = /\((.*):(.*)\)/;                                         // matches angular named route pattern
  const match = auxRoute?.match(namedRouteRegex);
  if (auxRoute && match !== null) {
    nestedRoute = `.*${escapeRegex(parentBaseUrl)}.*(.*${match[1]}:${match[2]}.*)`;      // matcher for single-spa activate function
  }
  return nestedRoute;
}

/**
 * regex escape a string
 * @param string
 */
export function escapeRegex(string: string): string {
  return string?.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
